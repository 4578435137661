<template>
  <div>
    <div class="min-h-50 d-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot && !loading">
      <div class="text-center">
        <b-icon icon="building" class="h1" />
        <p class="my-3">Ainda não há informações sobre o porte da empresa.</p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-5">
        <small class="mr-2">
          Ultima atualização {{ company.updated_at | dateFull }}
        </small>
        <unicon v-if="is_provider && enabled" name="redo" @click="handleSyncronize()" class="pointer small"
          style="font-size: 12px" height="16" width="16" />
      </div>
      <cards-list grid="4" responsive="2" gap="12" class="mt-3">
        <b-card class="w-100" body-class="d-flex flex-column justify-content-between"
          v-for="(card, index) in financial.cards" :key="index"
          :class="index === financial.cards.length - 1 ? 'mr-0' : 'mr-4'">
          <div>
            <span>{{ card.label }}</span>
          </div>
          <section class="d-flex w-100 justify-content-between align-items-center mt-1 scrollable-text">
            <h4 class="m-0" :class="card.error && 'text-danger'">
              {{ card.value || 0 | money }}
            </h4>
          </section>
          <small class="text-danger" v-if="card.error">
            {{ card.error_label }}</small>
        </b-card>
      </cards-list>
      <cards-list grid="2" responsive="1" gap="12">
        <b-card class="mt-3 w-100 mr-4">
          <div>
            <span>% de sua dependência:</span>
          </div>
          <div class="mt-4 d-flex align-items-center" v-if="financial && financial.revenue_dependency">
            <div class="risc-progress mt-4">
              <strong :style="`left: ${financial.revenue_dependency.measured}%`" class="value">
                {{ financial.revenue_dependency.measured.toFixed(2) }}%
              </strong>
              <div :style="`left: ${financial.revenue_dependency.measured}%`" class="marker" />
            </div>
          </div>
        </b-card>
        <b-card class="mt-3 w-100 mr-4">
          <div>
            <span>% de dependência para esse serviço:</span>
          </div>
          <div class="mt-4 d-flex align-items-center" v-if="financial && financial.revenue_dependency">
            <div class="risc-progress mt-4">
              <strong :style="`left: ${financial.revenue_dependency.desired}%`" class="value">
                {{ financial.revenue_dependency.desired }}%
              </strong>
              <div :style="`left: ${financial.revenue_dependency.desired}%`" class="marker" />
            </div>
          </div>
        </b-card>
        <b-card class="mt-3 w-100">
          <small class="d-block mb-4">
            Os dados de colaboradores estão incorretos? altere-os
            <router-link target="_blank" to="/my-enterprise/human-resources/employees/list">
              na gestão de pessoas de sua empresa
            </router-link>
          </small>
          <div>
            <span>
              Total de colaboradores
              <strong>{{ staff.total }} </strong>
            </span>
          </div>
          <div class="mt-4 d-flex scrollable-text">
            <img src="@/assets/images/employee.png" height="100" alt="" />
            <section class="mx-3">
              <strong class="d-block mb-2">Administradores</strong>
              <span>{{ staff.administrative }} </span>
            </section>
            <section>
              <strong class="d-block mb-2">Operacionais</strong>
              <span>{{ staff.operational }} </span>
            </section>
          </div>
        </b-card>
      </cards-list>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      service_id: "",
      provider_id: "",
      hasSnapshot: false,
      enabled: false,
      isEmpty: false,
      loading: false,
      staff: {},
      financial: {},
      company: {},
      invertValue: false,
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    handleClickChange() {
      this.invertValue = !this.invertValue;
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "company-size",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(({ data, ...response }) => {
        this.enabled = response.enabled;
        if (!data?.financial) {
          this.hasSnapshot = false;
        } else {
          this.hasSnapshot = true;
          this.company = response;
          this.staff = data?.staff;
          this.financial = data?.financial;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "company-size",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.getSnapshot();
  },
};
</script>
<style lang="scss">
.risc-progress {
  position: relative;
  width: 100%;
  background: rgb(37, 161, 16);
  background: linear-gradient(90deg,
      rgba(37, 161, 16, 1) 0%,
      rgba(193, 204, 50, 1) 32%,
      rgba(245, 226, 50, 1) 67%,
      rgba(255, 0, 0, 1) 100%);
  height: 40px;

  .value {
    margin-left: -5px;
    top: -25px;
    position: absolute;
  }

  .marker {
    top: -5px;
    background: transparent;
    height: 50px;
    position: absolute;
    width: 20px;
    border: 3px solid $text-color;
  }
}
</style>
